
#sectionE {max-width: 1440px;
    margin: auto;
    padding-top: 140px;
    padding-left: 32px;
    padding-right: 32px;
    color: #505079;
    padding-bottom: 60px;}


#contenuE h2 {color: #fb8842;
    font-family: 'lilita';
   text-align: center;
    font-weight: 400;
    font-size: 4em;}


#contenuE h3 {color:#505079;
    font-family: 'Eczar', serif;
    text-align: center;
    font-weight: 600;
    font-size: 2em;}



.textE {font-size: 18px;
    margin-top: 30px;
  columns: 2;
  column-gap: 40px;
  font-family: 'Eczar', serif;
  line-height: 1.7em;}

#mountain {width: 100%;
text-align: center;}

#mountain img {width: 80%;}

.firstE::first-letter{ font-size: 300%;}
  

@media(max-width: 720px)

{
    #contenuE h2 {font-size: 3em;}


    .textE{columns: 1;}

    #mountain img {width: 100%;}

}